import React from 'react'
import Button from '@mui/joy/Button';
import "./style.css"
import { useNavigate } from 'react-router-dom';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';


export const TourCard = (props) => {
  const data = props.props;
  
  const navigate = useNavigate();

  const { t } = useTranslation();

  function getRandomImage() {
    const {image} = data;
    const randomIndex = Math.floor(Math.random() * image.length);
    return image[randomIndex]?.path;
  }

  const formattedttitle = data.title.toLowerCase().replace(/\s+/g, '-');


  return (
    <>
      <div className="body">
        <div className="product-card">
          <div className="image-card">
            <img src={ getRandomImage() ? getRandomImage() : "https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286"}
              loading="lazy"
              alt='tour'
              style={{width: '100%',height:"100%"}}
            />
          </div>
          <div className="product-content">
            
            <header className="product-title">{data.title}</header>
            <div className="product-rating">
            <Rating name="read-only" value={data.rate} readOnly />

            </div>
            <section className='product-info'>
              <span className='desc'>{data.location.province} , {data.location.district}  </span> 
            </section>
            <section className='product-info'>
              <span className='desc'> {data.price} TL / {t('day')}  </span> 
              <section className='product-price-details'>
              <Button
                variant="solid"
                size="sm"
                className='butn--fill butn'
                sx={{ fontWeight: 600 ,minWidth:70, backgroundColor: "#e67e22"}}
                onClick={()=>{navigate(`../tour/${data.campingTourId}`)}}
                >
            {t('explore')}
          </Button>
            </section>
            </section>
            <div className='seperator'></div>
            
          </div>
        </div>
      </div>
    </>
  )
}
