import React, { useEffect, useState } from 'react'
import Logo from '../../resources/images/LogoName.png';
import {LanguageSelector} from '../LanguageSelector/LanguageSelector';
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { Search,StyledInputBase,StyledAutocomplete} from './comp/styledInputs';
import { convertToDetailedDate, formatDate, getDate, isValidDateFormat } from '../../utils';
import { ResponsiveNavbar } from './ResponsiveNavbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import EventIcon from '@mui/icons-material/Event';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { TextField } from '@mui/material';



export const NonLoggedNavbar = (props) => {
    const [show,setShow] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); 
    const searchParams = new URLSearchParams(location.search);
    const [checkIn,setCheckIn] = useState( isValidDateFormat(searchParams.get('checkin')) ? searchParams.get('checkin'):'');
    const [checkout,setCheckout] = useState( isValidDateFormat(searchParams.get('checkout')) ? searchParams.get('checkout'): '');

    const [openDate, setOpenDate] = useState(false);
    const { t } = useTranslation();
    const [date, setDate] = useState([{
        startDate: isValidDateFormat(checkIn) ? new Date(convertToDetailedDate(checkIn)) : new Date(),
        endDate: isValidDateFormat(checkout) ? new Date(convertToDetailedDate(checkout)) :  null,
        key:  'selection' 
    }]);
    const [formatted,setformated] = useState( isValidDateFormat(checkIn) && isValidDateFormat(checkout) ? getDate(checkIn,checkout) : t('AddDate'));
    const [search,setSearch] = useState('');
    const [cities,setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);
    const query2 = searchParams.get('province');
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          fetch("https://www.kamplasana.com/api/cities", requestOptions)
            .then((response) => response.text())
            .then((result) => {
              const resultJson = JSON.parse(result);
              setCities(resultJson)
            })
            .catch((error) => console.error(error));
    }, [])
    useEffect(() => {
        const initialCity = cities.find(city => city.text === query2);
        setSelectedCity(initialCity || null);
      }, [query2, cities]);


    const renderLeftSide = () =>(
    <>
        <div className='col-2 col-md-1 col-sm-2 barscol'>
            <button onClick={()=>{setShow(true)}} className='btn btn-light'>
                <i className="fa fa-bars" aria-hidden="true"></i>
            </button>
        </div>
        <div className='logo-col col-1 col-md-2 col-sm-2'>
            <div style={{cursor:'pointer'}} onClick={()=>{navigate('../home')}}  className="logo">
                <img style={{width:180,maxWidth:200,padding:25,borderBottomLeftRadius:20,borderBottomRightRadius:20}} src={Logo}/>
            </div>
        </div>
    </>
    )

    const renderMiddleSide = () =>(
        props.isSearchVisible && 
        <Search className='loggednav'>
            <StyledInputBase
                placeholder={formatted}
                className='searchs'
                onClick={(e)=>{setOpenDate(!openDate)}}
                InputProps ={{
                    startAdornment: <EventIcon className='me-2'/>
                }}
            />
            <div className='datepicker' style={{zIndex:90}}>
                {openDate && (
                    <DateRange 
                        onChange={(item) => {
                            setDate([item.selection]);
                            let x = formatDate([item.selection][0].startDate);
                            let y = formatDate([item.selection][0].endDate);
                            setCheckIn(x);
                            setCheckout(y);
                            setformated(getDate(x,y))
                            if(x===y ){
                                setOpenDate(true);
                            }
                            else{
                                setOpenDate(false);
                            }                                     
                        }}
                        minDate={new Date()}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        rangeColors={['#d66']}
                        
                    />
                )}
            </div>
            <StyledAutocomplete
                options={cities}
                getOptionLabel={(option) => option.text}
                className='searchs-last'
                value={selectedCity} // Use `value` instead of `defaultValue`
                onChange={(event, newValue) => {
                  setSelectedCity(newValue);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t('SearchLocation')}
                    InputProps={{
                    ...params.InputProps,
                    startAdornment:    <FmdGoodIcon className='me-2' />,
                    endAdornment: (
                        <IconButton
                        onClick={() => {
                            navigate(`?province=${params.inputProps.value}&checkin=${checkIn}&checkout=${checkout}`);
                            window.location.reload();
                        }}
                        >
                        <SearchIcon />
                        </IconButton>
                    ),
                    disableUnderline: true,
                    }}
                />
                )}
            />
        </Search>
    )

    const renderRightSide = () =>(

        <div style={{flex:1}} className='navlinks-cont col-2 col-md-5 col-sm-5'>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/about')}} className="nav_link">{t('Aboutus')}</li>
            </div>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/camps')}} className="nav_link">{t('Camps')}</li>
            </div>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/tours')}} className="nav_link">{t('Tours')}</li>
            </div>
            <div className='nav-links-mobile'>
                <li className="nav_link" onClick={()=>{navigate('/blogs')}}>{t('Blog')}</li>
            </div>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/login')}} className="nav_link last-child">{t('login')}</li>
            </div>

            <li>
                <button onClick={()=>{navigate('/signup-camper')}} className='btn btn-dark signup-fluid'> {t('signup')}</button>
            </li>
            <div className='nav-links-mobile' >
                <div className="ps-2"> <LanguageSelector/> </div>
            </div>

        </div>
    )

    return(
        <div className='pb-3 bg-main'>
            <div className=' px-3 container-fluid nav-cont'>
                {renderLeftSide()}
                {renderMiddleSide()}
                {renderRightSide()}
            </div>
            <ResponsiveNavbar 
                setShow={setShow}
                show={show}
                navigate={navigate}
                Logo={Logo}
                logged={false}
                t={t}
            />
        </div>
    )
}
