import React, { useState,useEffect } from 'react';
import './login.css';
import { Footer, Navbar} from '../../common';
import { useNavigate } from "react-router-dom";
import {Domain} from '../../Domain';
import {Button,Modal} from 'react-bootstrap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';



export const Login = () => {
    const navigate = useNavigate();
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow(false);
    const handleShow2 = () => setShow(true);
    const [success,setSuccess] = useState(false);

    const [icon, setIcon] = useState('on');
    const [type,setType] = useState('password');
    const [message,setMessage] = useState('');
    const [role,setRole]= useState('role');
    const [googleResponse,setGoogleResponse] = useState('')

    // useEffect(() => {
    //   return () => {
    //     if(localStorage.getItem("accessToken")){
    //        navigate('../home'); 
    //     }
    //   }
    // }, [])
    // console.log('role',role);
    const { t } = useTranslation();
    const handleToggle = () => {
        if (type==='password'){
           setIcon('off');
           setType('text')
        } else {
           setIcon('on')
           setType('password')
        }
     }
    
    const handleLogin = ()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "email": email,
        "password": password
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/sessions`, requestOptions)
        .then(response => response.json())
        .then(result => {
            localStorage.setItem('accessToken',result.accessToken);
            localStorage.setItem('refreshToken',result.refreshToken);
            localStorage.setItem('username',(result.user.firstName +" " + result.user.lastName));
            const temp =JSON.stringify(result.user);
            localStorage.setItem('user', temp);
            setSuccess(true);
            navigate('../home');
        })
        .catch(error =>{
            setSuccess(false);
            setMessage(t("warninglogin"));
            handleShow();
        });
    }

    const handleGoogleSuccess = (credentialResponse) => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "token": credentialResponse.credential
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://www.kamplasana.com/api/sessions/google", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            //Just got created basically
            if(result.user.createdAt == result.user.updatedAt){
                setGoogleResponse(result);
                setSuccess(true);
                setShow2(true)
            }
            if(result.user.createdAt != result.user.updatedAt){
                console.log("cos el na3ga");
                localStorage.setItem('accessToken',result.accessToken);
                localStorage.setItem('refreshToken',result.refreshToken);
                localStorage.setItem('username',(result.user.firstName +" " + result.user.lastName));
                const temp =JSON.stringify(result.user);
                localStorage.setItem('user', temp);
                setSuccess(true);
                navigate('../home');
            }
            else{
                setSuccess(false);
                setMessage(t("warninglogin"));
                handleShow();
            }
        })
        .catch((error) => {
            console.error(error)
            setSuccess(false);
            setMessage(t("warninglogin"));
            handleShow();
        });
    };
    const handleUpdate = () =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${googleResponse.accessToken}`);
        myHeaders.append("x-refresh", `Bearer ${googleResponse.refreshToken}`);


        const raw = JSON.stringify({
        "role": role
        });

        const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch("https://www.kamplasana.com/api/users", requestOptions)
        .then((response) => response.text())
        .then((result) => {
            localStorage.setItem('accessToken',googleResponse.accessToken);
            localStorage.setItem('refreshToken',googleResponse.refreshToken);
            localStorage.setItem('username',(googleResponse.user.firstName +" " + googleResponse.user.lastName));
            googleResponse.user.role = role;
            const temp =JSON.stringify(googleResponse.user);
            localStorage.setItem('role',role)
            localStorage.setItem('user', temp);
            setSuccess(true);
            navigate('../home');
            setShow2(false);
        })
        .catch((error) => console.error(error));
    }

    const handleGoogleFailure = (error) => {
        console.log("Google login failed", error);
    };

    return (
        <GoogleOAuthProvider clientId="774225248843-d3hkskpd25fdi41rbq7ovhmgb42hjk8f.apps.googleusercontent.com">
            <div className='login-area bg-light'>
                <div className="guest-area-top">
                    <div className="container">
                        <div className="guest-header">
                            <Navbar isSearchVisible={false}/>
                            <div className="guest-top-title mt-2 p-2 ">{t('LoginHeader')}</div>
                            <p className='p-2'>{t('Login2')}</p>
                        </div>
                    </div>
                </div>
                <div  className="container mb-3">
                    <div className="row gx-0">
                        <div className="col-lg-6 order-1">
                            <div className="login-left-area">
                                <span>{t('Login3')}</span>
                                <p>{t('Login4')}</p>
                                <div className="login-left-title">{t('Login5')}</div>
                                <a onClick={()=>{navigate('../signup-camper')}} className="button brown large">{t('signup')}</a>
                            </div>
                        </div>
                        <div className="col-lg-6 order-2">
                            <div className="login-right-area">
                                <div className="guest-login-area">
                                    <div noValidate="" className="guest-login-body">
                                        <div className="form-area">
                                            <p>{t('mail1')}</p>
                                            <input type="text" onChange={(e)=>{setEmail(e.target.value.toLowerCase());}} placeholder={t('placeHolderEmail')} className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <svg style={{width:24,height:24}} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                <path d="M5.66634 5.6665H28.333C29.8913 5.6665 31.1663 6.9415 31.1663 8.49984V25.4998C31.1663 27.0582 29.8913 28.3332 28.333 28.3332H5.66634C4.10801 28.3332 2.83301 27.0582 2.83301 25.4998V8.49984C2.83301 6.9415 4.10801 5.6665 5.66634 5.6665Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                <path d="M31.1663 8.5L16.9997 18.4167L2.83301 8.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            </svg>
                                            <em></em>
                                            <div className="valid"></div>
                                        </div>
                                        <div className="form-area">
                                            <p>{t('password21')} *</p>
                                            <input type={type} value={password} onChange={(e)=>{setPassword(e.target.value)}} placeholder="********" formcontrolname="email" className="mat-input-element mat-form-field-autofill-control form-text cdk-text-field-autofill-monitored cdk-text-field-autofilled"/>
                                            <span class="flex justify-around items-center" onClick={handleToggle}>
                                                {icon === 'on' ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                            </span>
                                            <em></em>
                                            <div className="valid"></div>
                                        </div>
                                        <div className="buttons between"><a  href='../forget-pass' className="forgot-password">{t('forgotPassword')}</a><button onClick={handleLogin} className="button brown large">{t('login')}</button></div>
                                        <div className="divider">
                                            <hr/>
                                            <span>{t('or')}</span>
                                            <hr/>
                                        </div>
                                        <div className='googleCont' >
                                       
                                        <div className='row mt-3'>
                                        <GoogleLogin
                                            onSuccess={handleGoogleSuccess}
                                            onError={handleGoogleFailure}
                                        />
                                        </div>
                                     
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>{success ? t("SuccessTitle") : t("WarningTitle") }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{message}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t('close')}
                    </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} size='lg' onHide={handleClose}>
                    <Modal.Header style={{alignItems:'center',justifyContent:'center'}} closeButton={false}>
                    <Modal.Title style={{textAlign:'center'}}>Hesap türünüz seçin</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='googleCont'>

                    <div className='row' style={{width:'100%'}}>
                        <div class="grid">
                        <label class="card">
                            <input name="plan" onClick={()=>{setRole('camper')}} class="radio" type="radio"/>
                            <span class="plan-details">
                            <span class="plan-type">Kampçı</span>
                            </span>
                        </label>
                        <label class="card">
                            <input name="plan" onClick={()=>{setRole('campingSeller')}} class="radio" type="radio"/>
                            <span class="plan-details">
                            <span class="plan-type">Kamp Sahibi</span>
                            </span>
                        </label>
                        <label class="card">
                            <input name="plan" onClick={()=>{setRole('campingTour')}} class="radio" type="radio"/>
                            <span class="plan-details">
                            <span class="plan-type">Tur Sahibi</span>
                            </span>
                        </label>
                        </div>

                    </div>

                    <Button style={{width:'50%',backgroundColor:'#C74F2D',marginTop:20,borderColor:"#C74F2D"}} variant="secondary" onClick={()=> handleUpdate()}>
                        {t('Submit')}
                    </Button>
                    </Modal.Body>
                </Modal>
                <Footer />
            </div>
        </GoogleOAuthProvider>
    )
}
