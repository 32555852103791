import React, { useEffect, useState } from 'react';
import { Safety } from '../Home/Components/Safety';
import { SafetyPartners } from '../Home/Components/SafetyPartners';
import { Footer,Navbar} from '../../common';
import './details.css';
import 'leaflet/dist/leaflet.css'
import { Domain } from '../../Domain';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel'
import { Helmet } from 'react-helmet-async';

export const BlogDetails = () => {
  let {id} = useParams();
  const {state} = useLocation();

  const [blog,setBlog] = useState('empty');
  const navigate = useNavigate();


  function Example(props)
  {


      return (
          <Carousel height={450}>
              {
                  blog.image.map( (item) => <Item item={item} /> )
              }
          </Carousel>
      )
  }

  function Item(props)
  {
      const {item} = props;
      return (
          <img className='my-img' src={item}>
          </img>
      )
  }

  useEffect(() => {
    if(!id.startsWith('blog_')){
      id = id.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');  
      let myHeaders = new Headers();
      let requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
      };  
      fetch(`${Domain}/api/blogs?search=${id}&limit=${1}`, requestOptions)
      .then(response => response.json())
      .then(result => {
          if(result.length == 0){
                setBlog(state)
            }
          else{
            var requestOptions2 = {
              method: 'GET',
              redirect: 'follow'
            };
            fetch(`${Domain}/api/blogs/${result[0].blogId}`, requestOptions2)
              .then(response => response.json())
              .then(result => 
                {setBlog(result);})
              .catch(error => {
                navigate('/error')
            });
          }
      })
      .catch(error =>{ 
          console.log("err",error);
          navigate('/error')

      });
    }
    else{
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      fetch(`${Domain}/api/blogs/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
           if(result){
            setBlog(result);
           }
           else{
            navigate('/error')
           }
        })
        .catch(error => {
            console.log('error', error);
            navigate('/error');
        });
    }
  },[]);


  return (
    blog!= 'empty' &&
    <>
      <Helmet>
				<title>{blog.title}</title>
			</Helmet>
        <div className=''>
            <Navbar isSearchVisible={false}/>
            <div style={{display:'flex',justifyContent:'center',flexDirection:'column',textAlign:'center'}} className='pt-5 px-2  bg-light home-body overflow-hidden blog-home-body'>
                
                <h1 style={{fontWeight:700}} className='mb-4'>{blog.title}</h1>

                <div className='carousel mb-3'>
                  <Example/>
                </div>
                <div className='mx-5 blogdata' dangerouslySetInnerHTML={{ __html: blog.body }} />
                </div>
            </div>
            <div className='bg-lgreen py-4'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
             <Footer current={''}/>
    </>

  )
}
