import Lottie from 'lottie-react';
import React from 'react';
import Animation from "../../../resources/lottiefiles/NoResultFound.json"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
export const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const clearFilter = () =>{
    localStorage.removeItem('cacheMinPrice');
    localStorage.removeItem('cacheMaxPrice');
    localStorage.removeItem('titleTour');
    localStorage.removeItem('sortby');
    localStorage.removeItem('sortorder');
    localStorage.removeItem('ActivitiesTours');
    localStorage.removeItem('AdditionalTours');
    localStorage.removeItem('AmenitiesTours');
    localStorage.removeItem('publicAreaTours');
    localStorage.removeItem('TerrainTours');
    navigate('camps')
    window.location.reload();
  }
  
  return (
    <div className='d-flex align-items-center justify-content-center pb-5'>
      <div className='d-flex align-items-center justify-content-center flex-column' >
          <h2>{t('NoResutsFoundTitle')}</h2>
          <h5>{t('NoResutsFoundHeader')}</h5>
          <Lottie  style={{maxWidth:400}}  animationData={Animation} />
          <button onClick={clearFilter} className='btn btn-dark signup-fluid'>{t('clearFilterBtn')}</button>
      </div>
    </div>

  )
}
