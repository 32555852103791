import React from 'react';
import { Safety } from '../Home/Components/Safety';
import { Footer,Navbar } from '../../common';
import { Container, Typography, Box,Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export const Kvkk = () => {
  return (
        <div className='bg-main'>
            <Helmet>
                <title>Kamplasana | Gizlik Politikası</title>  
                <meta name="description" content={"Kamplasanannın  Gizlik Politikası "} />
            </Helmet>
            <div className='home-body overflow-hidden'>
            <Navbar isSearchVisible={false}/>       
            </div>
            <div className='px-3 py-2'>
            
        
            <Container maxWidth="xl" sx={{ marginTop: 4}}>

                <Grid container spacing={5} className='mb-4'>
                    <Grid item xs={12} >
                    <Box sx={{background: 'white',padding:5,borderRadius:10}} >
                    <Typography variant="h2" style={{fontWeight:500}} gutterBottom>
            Gizlilik Politikası
            </Typography>
            <p><strong>Son Güncelleme: 12.11.2024</strong></p>
            <p>Bu Gizlilik Politikası, Hizmeti kullandığınızda bilgilerinizin toplanması, kullanılması ve ifşa edilmesi ile ilgili politikalarımızı ve prosedürlerimizi tanımlar; ayrıca gizlilik haklarınız ve yasanın sizi nasıl koruduğu hakkında bilgi verir.</p>
            <p>Hizmeti sağlamak ve iyileştirmek için Kişisel Verilerinizi kullanıyoruz.</p>

            <h2>Yorum ve Tanımlar</h2>
            <h3>Yorum</h3>
            <p>Baş harfi büyük olan kelimelerin, aşağıdaki koşullarda tanımlanan anlamları vardır. Aşağıdaki tanımlar, tekil veya çoğul olarak geçip geçmemesine bakılmaksızın aynı anlama sahiptir.</p>

            <h3>Tanımlar</h3>
            <p>Bu Gizlilik Politikası amacıyla:</p>
            <ul>
                <li>
                    <p><strong>Hesap</strong> Hizmetimize veya Hizmetimizin bazı bölümlerine erişmeniz için oluşturulmuş benzersiz bir hesap anlamına gelir.</p>
                </li>
                <li>
                    <p><strong>Bağlı Kuruluş</strong> Bir tarafın kontrol ettiği, o tarafın kontrolü altında bulunan veya o tarafla ortak kontrol altında olan bir kuruluş anlamına gelir. "Kontrol", müdürlerin veya diğer yönetim makamlarının seçiminde oy hakkına sahip hisselerin, sermaye paylarının veya diğer menkul kıymetlerin %50'sine veya daha fazlasına sahip olunması anlamına gelir.</p>
                </li>
                <li>
                    <p><strong>Şirket</strong> (bu Sözleşmede "Şirket", "Biz", "Bize" veya "Bizim" olarak anılabilir) Kamplasana, Fulya, Yeşilçimen Sokağı Polat Tower Residence Bağımsız Bölüm 12/430, 34394 Şişli/İstanbul'u ifade eder.</p>
                </li>
                <li>
                    <p><strong>Çerezler</strong> bir web sitesi tarafından bilgisayarınıza, mobil cihazınıza veya başka bir cihazınıza yerleştirilen, bu web sitesindeki tarama geçmişinizin ayrıntılarını içeren küçük dosyalardır.</p>
                </li>
                <li>
                    <p><strong>Ülke</strong> Türkiye'yi ifade eder.</p>
                </li>
                <li>
                    <p><strong>Cihaz</strong> bir bilgisayar, cep telefonu veya dijital tablet gibi Hizmete erişim sağlayan herhangi bir cihaz anlamına gelir.</p>
                </li>
                <li>
                    <p><strong>Kişisel Veri</strong>, tanımlanabilir bir birey ile ilgili olan herhangi bir bilgi anlamına gelir.</p>
                </li>
                <li>
                    <p><strong>Hizmet</strong> Web Sitesini ifade eder.</p>
                </li>
                <li>
                    <p><strong>Hizmet Sağlayıcı</strong> Şirket adına verileri işleyen herhangi bir gerçek veya tüzel kişiyi ifade eder. Bu, Hizmeti kolaylaştırmak, Şirket adına Hizmeti sağlamak, Hizmet ile ilgili hizmetleri sunmak veya Hizmetin nasıl kullanıldığını analiz etmemize yardımcı olmak amacıyla Şirket tarafından istihdam edilen üçüncü taraf şirketler veya bireyler anlamına gelir.</p>
                </li>
                <li>
                    <p><strong>Üçüncü Taraf Sosyal Medya Hizmeti</strong>, bir Kullanıcının Hizmeti kullanmak için giriş yapabileceği veya bir hesap oluşturabileceği herhangi bir web sitesi veya sosyal medya web sitesini ifade eder.</p>
                </li>
                <li>
                    <p><strong>Kullanım Verisi</strong>, Hizmetin kullanımı yoluyla veya Hizmet altyapısının kendisinden (örneğin bir sayfa ziyaretinin süresi gibi) otomatik olarak toplanan verilerdir.</p>
                </li>
                <li>
                    <p><strong>Web Sitesi</strong> Kamplasana’yı ifade eder ve <a href="https://kamplasana.com" rel="external nofollow noopener" target="_blank">https://kamplasana.com</a> adresinden erişilebilir.</p>
                </li>
                <li>
                    <p><strong>Siz</strong>, Hizmete erişen veya Hizmeti kullanan bireyi veya bu bireyin adına erişen veya kullanan şirketi ya da başka bir tüzel kişiliği ifade eder.</p>
                </li>
            </ul>

            <h2>Kişisel Verilerinizin Toplanması ve Kullanılması</h2>
            <h3>Toplanan Veri Türleri</h3>

            <h4>Kişisel Veri</h4>
            <p>Hizmetimizi kullanırken, sizinle iletişim kurmak veya sizi tanımlamak için kullanılabilecek belirli kişisel bilgileri sağlamanızı isteyebiliriz. Kişisel olarak tanımlanabilir bilgiler şunları içerebilir, ancak bunlarla sınırlı değildir:</p>
            <ul>
                <li><p>E-posta adresi</p></li>
                <li><p>Ad ve soyad</p></li>
                <li><p>Telefon numarası</p></li>
                <li><p>Adres, Eyalet, İlçe, Posta kodu, Şehir</p></li>
                <li><p>Kullanım Verisi</p></li>
            </ul>

            <h4>Kullanım Verisi</h4>
            <p>Kullanım Verisi, Hizmet kullanıldığında otomatik olarak toplanır.</p>
            <p>Kullanım Verisi, Cihazınızın İnternet Protokolü adresi (ör. IP adresi), tarayıcı türü, tarayıcı sürümü, ziyaret ettiğiniz Hizmet sayfaları, ziyaretinizin tarihi ve saati, bu sayfalarda harcanan süre, benzersiz cihaz tanımlayıcıları ve diğer tanılama verileri gibi bilgileri içerebilir.</p>
            <p>Hizmete bir mobil cihazdan veya mobil cihaz aracılığıyla eriştiğinizde, kullandığınız mobil cihaz türü, mobil cihazınıza ait benzersiz kimlik numarası, mobil cihazınızın IP adresi, mobil işletim sistemi, mobil internet tarayıcısı türü, benzersiz cihaz tanımlayıcıları ve diğer tanılama verileri gibi belirli bilgileri otomatik olarak toplayabiliriz.</p>

            <h4>Üçüncü Taraf Sosyal Medya Hizmetlerinden Alınan Bilgiler</h4>
            <p>Şirket, Hizmeti kullanmak için Üçüncü Taraf Sosyal Medya Hizmetlerinden giriş yapma ve hesap oluşturma imkanı sunar:</p>
            <ul>
                <li>Google</li>
                <li>Facebook</li>
                <li>Instagram</li>
                <li>Twitter</li>
                <li>LinkedIn</li>
            </ul>
            <p>Üçüncü Taraf Sosyal Medya Hizmeti aracılığıyla kayıt olmayı veya bize erişim izni vermeyi seçerseniz, Üçüncü Taraf Sosyal Medya Hizmeti hesabınızla ilişkili olan adınız, e-posta adresiniz, etkinlikleriniz veya kişi listeniz gibi Kişisel Verileri toplayabiliriz.</p>
            <p>Üçüncü Taraf Sosyal Medya Hizmeti hesabınız aracılığıyla ek bilgi paylaşmayı tercih edebilirsiniz. Bu bilgileri ve Kişisel Verileri sağlamayı seçtiğinizde, Şirkete bu bilgileri, bu Gizlilik Politikası ile tutarlı bir şekilde kullanma, paylaşma ve saklama izni vermiş olursunuz.</p>

            <h4>Çerezler ve Takip Teknolojileri</h4>
            <p>Çerezleri ve benzer takip teknolojilerini Hizmetimizdeki etkinliği izlemek ve belirli bilgileri saklamak için kullanıyoruz. Kullanılan takip teknolojileri arasında bilgi toplamak ve izlemek, Hizmetimizi iyileştirmek ve analiz etmek için işaretçiler, etiketler ve betikler bulunur. Kullandığımız teknolojiler şunları içerebilir:</p>
            <ul>
                <li><strong>Çerezler veya Tarayıcı Çerezleri.</strong> Çerez, Cihazınıza yerleştirilen küçük bir dosyadır. Tarayıcınıza tüm Çerezleri reddetmesi veya bir Çerezin gönderildiğini belirtmesi talimatını verebilirsiniz. Ancak, Çerezleri kabul etmezseniz, Hizmetimizin bazı bölümlerini kullanamayabilirsiniz. Tarayıcı ayarınızı Çerezleri reddedecek şekilde ayarlamadığınız sürece Hizmetimiz Çerezleri kullanabilir.</li>
                <li><strong>Web İşaretçileri.</strong> Hizmetimizin belirli bölümleri ve e-postalarımız, örneğin bu sayfaları ziyaret eden kullanıcıları saymamızı veya bir e-postayı açmamızı sağlamak ve sistemsel olarak ilgili diğer web sitesi istatistikleri için kullanılan küçük elektronik dosyalar olan web işaretçileri (açık gifler, piksel etiketleri ve tek pikselli gifler olarak da adlandırılır) içerebilir.</li>
            </ul>
            <p>Çerezler hakkında daha fazla bilgi için lütfen Çerez Politikamıza veya Gizlilik Politikamızdaki Çerezler bölümüne bakın.</p>

                    </Box>
                    </Grid>
                    
                </Grid>
            </Container>
            </div>
            <div className='bg-lgreen py-5'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
            <div className='bg-light'>
                <div className='home-body overflow-hidden'>
                </div>    
            </div>
            <Footer current={''}/>

    </div>
  )
}
