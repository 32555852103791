import React, { useRef, useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Box,Card,CardCover,AspectRatio,IconButton} from '@mui/joy';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { t } from 'i18next';

export const CloudinaryWidget = ({ apiKey, cloudName, folder, maxWidth, maxHeight, onUploadSuccess,images,setImages}) => {
  
  const widgetRef = useRef();
  const [uploadedImages, setUploadedImages] = useState(images);
  const [previewImages, setPreviewImages] = useState(images);
  
  const onClick = React.useCallback(({target: {innerText}}) => {
  }, [])

  const onCopy = React.useCallback(() => {
    setCopied(true);
  }, [])

  const openWidget = () => {
    widgetRef.current.open();
  };
  useEffect(() => {
    if(uploadedImages.length <= 0){
      setUploadedImages([...uploadedImages,...images])
    }
  }, [images])
  


  const RemoveFromArr = (valueToRemove) => {
    const newArray = uploadedImages.filter(item => item !== valueToRemove);
    setPreviewImages(newArray);
    setUploadedImages(newArray);
    setImages(newArray);
  }
  const [copied, setCopied] = React.useState(false);


  useEffect(() => {
    if (window.cloudinary) {
      widgetRef.current = window.cloudinary.createUploadWidget(
        {
          cloudName: cloudName,
          apiKey: apiKey,
          uploadPreset: 'tkk2pscx', // Set this to your Cloudinary upload preset
          folder: folder,
          maxFileSize: 10485760, // Set maximum file size (10MB in this example)
          cropping: true,
          croppingAspectRatio: 1.0, // Set the aspect ratio as needed
          maxImageWidth: maxWidth,
          resourceType: 'image', // Restrict to images only
          maxImageHeight: maxHeight,
          clientAllowedFormats: ['jpg', 'png', 'gif','jpeg'], // Allow specific formats
          quality: 80, // Set the desired quality percentage (adjust as needed)
        },
        (error, result) => {
          if (!error && result && result.event === 'success') {
            onUploadSuccess(result.info.secure_url);
            setUploadedImages((prevImages) => [...prevImages, result.info.url]);
            setImages((prevImages) => [...prevImages, result.info.url]);
            widgetRef.current.close(true)
          }
        }
      );
    }
  }, [images]);


  return (
    <div>
      <div>
        <div onClick={openWidget} className='dropzoneStlyle'>
          <p>{t("clicktaz")}</p>
        </div>
      <div className='row mt-5 ms-2'>
        {uploadedImages.map((imageUrl) => (
              <Card
              variant="plain"
              sx={{
                width: 300,
                bgcolor: 'initial',
                p: 0,
              }}
            >
              <Box sx={{ position: 'relative', padding:1 }}>
                <AspectRatio ratio="9/7">
                  <figure>
                    <img  key={imageUrl} src={imageUrl} alt="Uploaded" style={{ width: '100%',margin: '10px',}} />
                  </figure>
                </AspectRatio>
                <CardCover
                  className="gradient-cover"
                  sx={{
                    '&:hover, &:focus-within': {
                      opacity: 1,
                    },
                    opacity: 0,
                    transition: '0.1s ease-in',
                    background:
                      'linear-gradient(180deg, transparent 62%, rgba(0,0,0,0.00345888) 63.94%, rgba(0,0,0,0.014204) 65.89%, rgba(0,0,0,0.0326639) 67.83%, rgba(0,0,0,0.0589645) 69.78%, rgba(0,0,0,0.0927099) 71.72%, rgba(0,0,0,0.132754) 73.67%, rgba(0,0,0,0.177076) 75.61%, rgba(0,0,0,0.222924) 77.56%, rgba(0,0,0,0.267246) 79.5%, rgba(0,0,0,0.30729) 81.44%, rgba(0,0,0,0.341035) 83.39%, rgba(0,0,0,0.367336) 85.33%, rgba(0,0,0,0.385796) 87.28%, rgba(0,0,0,0.396541) 89.22%, rgba(0,0,0,0.4) 91.17%)',
                  }}
                >
                  {/* The first box acts as a container that inherits style from the CardCover */}
                  <div>
                    <Box
                      sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1.5,
                        flexGrow: 1,
                        alignSelf: 'flex-end',
                      }}
                    >
                      <CopyToClipboard
                        onCopy={onCopy}
                        options={{message: 'Whoa!'}}
                        text={imageUrl}>
                      <IconButton
                          size="sm"
                          variant="solid"
                          color="neutral"
                          onClick={onClick}
                          sx={{ ml: 'auto', bgcolor: 'rgba(0 0 0 / 0.2)' }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                      <IconButton
                        size="sm"
                        variant="solid"
                        color="neutral"
                        onClick={()=>{RemoveFromArr(imageUrl)}}
                        sx={{ bgcolor: 'rgba(0 0 0 / 0.2)' }}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                     
                    </Box>
                  </div>
                </CardCover>
              </Box>
            </Card>
        ))}
      </div>
    </div>
    </div>
  );
};