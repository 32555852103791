import React,{useEffect, useState} from 'react';
import { Footer,Navbar} from '../../common';
import { useDispatch, useSelector } from 'react-redux';
import { getCamps, getMapCamps} from '../../StateManagement/actions/camps';
import { Category } from './comp/category';
import { LodgeCard } from './comp/LodgeCard';
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import L from 'leaflet';
import { useLocation } from 'react-router-dom';
import Slider from '@mui/joy/Slider';
import HoverCheckbox from './comp/CheckBox';
import { Domain } from '../../Domain';
import { MapItem } from './comp/MapItem';
import SearchIcon from '@mui/icons-material/Search';
import {IconButton,Pagination,Button,TextField,styled,Autocomplete} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { LoadingModal,SortingSelector} from '../../common';
import { convertToDetailedDate, formatDate, getDate, isValidDateFormat } from '../../utils';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import EventIcon from '@mui/icons-material/Event';
import { NotFound } from './comp/NotFound';
import { Helmet } from 'react-helmet-async';

const customMarker = new L.icon({
  iconUrl: icon,    
  iconSize: [20, 30],
  iconAnchor: [20, 30]
});

function valueText(value) {
  return `${value}₺`;
}
const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    borer:'0px solid',
    minWidth:70,
    fontSize:   '12px',

    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      
    },
  },
}));
const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  color: 'inherit',
  '& .MuiOutlinedInput-root': {
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create('width'),
    borer:'0px solid',
    minWidth:70,
    fontSize:   '12px',
    '& fieldset': {
      border: 'none', // Remove border
    },
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      
    },
  },
  '& .MuiAutocomplete-endAdornment': {
    display: 'none', // Hide the dropdown icon
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  border: '0.5px solid',
  borderColor:'#000',
  width:'auto',
  fontSize:   '12px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));


export const AvailableLodges = () => {
  const cacheMaxPrice = localStorage.getItem('cacheMaxPrice') ? localStorage.getItem('cacheMaxPrice') : 4000 ;
  const cacheMinPrice = localStorage.getItem('cacheMinPrice') ? localStorage.getItem('cacheMinPrice') : 0 ;

  const [value, setValue] = React.useState([cacheMinPrice,cacheMaxPrice]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('cacheMinPrice',newValue[0]);
    localStorage.setItem('cacheMaxPrice',newValue[1]);
  };
  const [selectedCity, setSelectedCity] = useState(null);

  // Set the initial value based on query2

  const [search,setSearch] = useState('');
  const location = useLocation(); 
  const searchParams = new URLSearchParams(location.search);
  const checkin = searchParams.get('checkin');
  const checkout = searchParams.get('checkout');
  const query2 = searchParams.get('province');
  const [area,setArea] = useState(query2);
  const [cities,setCities] = useState([]);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [openDate, setOpenDate] = useState(false);
  const { t } = useTranslation();
  const [date, setDate] = useState([{
      startDate: isValidDateFormat(checkin) ? new Date(convertToDetailedDate(checkin)) : new Date(),
      endDate: isValidDateFormat(checkout) ? new Date(convertToDetailedDate(checkout)) :  null,
      key:  'selection' 
  }]);
  const [formatted,setformated] = useState( isValidDateFormat(checkin) && isValidDateFormat(checkout) ? getDate(checkin,checkout) : t('AddDate'));
  const [checkIn,setCheckIn] = useState( isValidDateFormat(searchParams.get('checkin')) ? searchParams.get('checkin'):'');
  const [checkOut,setCheckOut] = useState( isValidDateFormat(searchParams.get('checkout')) ? searchParams.get('checkout'): '');

 
  const [page,setPage] = useState(  localStorage.getItem('skip') ? localStorage.getItem('skip') : 1 );
  const {camps,loaded,mapCamps} = useSelector(state => state.CampsReducer);
  const pages = Math.ceil(mapCamps.length / 4.0 );

  const [additionalSupport,setAdditionalSupport] = useState([]);
  const [selectedAdditional,setSelectedAdditional] = useState(localStorage.getItem('AdditionalCamps') ? JSON.parse(localStorage.getItem('AdditionalCamps')): []);
  const [standardAmenities,setStandardAmenities]= useState([]);
  const [selectedAmenities,setSelectedAmenities] = useState(localStorage.getItem('AmenitiesCamps') ? JSON.parse(localStorage.getItem('AmenitiesCamps')): []);
  const [publicArea,setPublicArea]= useState([]);
  const [selectedPublicArea,setSelectedPublicArea] = useState(localStorage.getItem('publicAreaCamps') ? JSON.parse(localStorage.getItem('publicAreaCamps')): []);
  const [activities,setActivities]= useState([]);
  const [selectedActivies,setSelectedActivies] = useState(localStorage.getItem('ActivitiesCamps') ? JSON.parse(localStorage.getItem('ActivitiesCamps')): []);
  const [terrain,setTerrain]= useState([]);
  const [selectedTerrain,setSelectedTerrain] = useState(localStorage.getItem('TerrainCamps') ? JSON.parse(localStorage.getItem('TerrainCamps')): []);
  const [camptypes,setCamptypes] = useState([]);
  const [selectedcamptypes,setSelectedCamptypes] = useState(localStorage.getItem("selectedcamptypes") ? JSON.parse(localStorage.getItem("selectedcamptypes")) : [] );
  useEffect(() => {
    const initialCity = cities.find(city => city.text === query2);
    setSelectedCity(initialCity || null);
  }, [query2, cities]);

  
  let dispatch = useDispatch(); 
  const getCampsHandler = () =>{
    try{
      if(query2){
        dispatch(getCamps('','',query2));
        dispatch(getMapCamps('','',query2));
      }
      else if( !isValidDateFormat(checkin) || !isValidDateFormat(checkout) || !query2){
        dispatch(getCamps());
        dispatch(getMapCamps());
      }
      else{     
        dispatch(getCamps(checkin.trim(),checkout.trim(),query2));
        dispatch(getMapCamps(checkin.trim(),checkout.trim(),query2));
      }
    }
    catch(e){
      dispatch(getCamps('','',query2));
      dispatch(getMapCamps('','',query2));
    }
  }
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("https://www.kamplasana.com/api/cities", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultJson = JSON.parse(result);
        setCities(resultJson)
      })
      .catch((error) => console.error(error));
    getCampsHandler();
  }, []);

  const handleNavigationToCamp = (name) => {
    name = name.toLowerCase().replace(/\s+/g, '-');
    try{
      if( checkin === null || checkout === null){
        navigate(`../camp/${name}`);
      }
      else{    
        navigate(`../camp/${name}`);
      }
    }
    catch(e){
      navigate(`../camp/${name}`);
    }
  }

  useEffect(()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${Domain}/api/category/home/top`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const resp = JSON.parse(result);
        const temp2 = resp.filter(obj => obj.feature !== "88");
        const camptyas = temp2.filter(obj => obj.feature === "22");
        const publicArea = temp2.filter(obj => obj.feature === "33");
        const aminities = temp2.filter(obj => obj.feature === "44");
        const additionalSupport = temp2.filter(obj => obj.feature === "55");
        const activites = temp2.filter(obj => obj.feature === "66");
        const terrain = temp2.filter(obj => obj.feature === "77");
        setCamptypes(camptyas);
        setPublicArea(publicArea);
        setStandardAmenities(aminities);
        setAdditionalSupport(additionalSupport);
        setActivities(activites);
        setTerrain(terrain);
      })
      .catch(error => console.log('error', error));
  },[])

  const handleCategoryChanges = (item) => {
    const getFeatureArray = (feature) => {
      switch (feature) {
        case '33': return selectedPublicArea;
        case '44': return selectedAmenities;
        case '55': return selectedAdditional;
        case '66': return selectedActivies;
        case '77': return selectedTerrain;
        default: return [];
      }
    };

    const setFeatureArray = (feature, array) => {
      switch (feature) {
        case '33': setSelectedPublicArea(array); break;
        case '44': setSelectedAmenities(array); break;
        case '55': setSelectedAdditional(array); break;
        case '66': setSelectedActivies(array); break;
        case '77': setSelectedTerrain(array); break;
        default: break;
      }
    };

    let temp = [...getFeatureArray(item.feature)];
    const index = temp.indexOf(item.code);
    if (index !== -1) {
      temp.splice(index, 1);
    } else {
      temp.push(item.code);
    }
    setFeatureArray(item.feature, temp);
  };

  const marks = [
    {
      value: 50,
      label: '50₺',
    },
    {
      value: 4000,
      label: '4000₺',
    },
  ];

  return (
    <>
      <Navbar isSearchVisible={true}/>
        <Helmet>
        <title>Kamplasana | {query2 ?`${query2}'da kamp alanlari` : "Türkiye'de kamp alanları"}</title>  
        <meta name="description" content={query2 ?`Kamplasana ile ${query2}'da kamp maceranıza başlayın`: "Türkiye'deki tüm kamp alanlarını keşfedin"} />
			</Helmet>
        <div className='bg-main pt-2  '>
          <div className='bg-white'>
            <div style={{width:'auto'}}>
            {!show && 
              <div className='border py-2' style={{ padding: '10px 10px', display: 'flex', alignItems: 'center', width: 'auto',overflow:'scroll' }}>
                  {
                    camptypes.map((item)=>(
                      <Category onClick={()=>{
                        const index = selectedcamptypes.indexOf(item.code);
                        const temp = selectedcamptypes;
                        if(index === -1){
                          temp.push(item.code);
                        }
                        else{
                          temp.splice(index,1);
                        }
                        setSelectedCamptypes(temp);
                        localStorage.setItem("selectedcamptypes",JSON.stringify(temp));
                        getCampsHandler();
                      }} name={item.name} selectedTypes={selectedcamptypes} code={item.code} />
                    ))
                  }
                  <Button onClick={() => setShow(true)} className='category'  sx={{ p: '5px 20px', display: 'flex',justifyContent:'flex-start', alignItems: 'center', minWidth:190, height:40, marginInline:1,fontSize:12,fontWeight:'bold' }} variant="outlined">
                    {t("catBtn")}
                  </Button>
                  <Search>
                  <StyledInputBase
                        placeholder={t("CampName")}
                        className='searchs-last'
                        onChange={(e)=>{setSearch(e.target.value)}}
                        InputProps={{
                        endAdornment:   
                        <IconButton onClick={()=>{localStorage.setItem('title',search); window.location.reload();}}>
                            <SearchIcon />
                        </IconButton>
                    }}/>
                </Search>
                <SortingSelector/>
              </div>
            }
            </div>    
            <div style={{width:'auto'}} className='searchBar-flex'>
              <div className='border py-4' style={{ padding: '20px 20px', display: 'flex', alignItems: 'center', width: 'auto', justifyContent:'center'}}>
                    <Search style={{ width: 'auto',display: 'flex', p: '5px 20px',justifyContent:'flex-start', alignItems: 'center', height:'auto'}}>
                      <StyledInputBase
                      placeholder={t('AddDate')}
                      value={formatted}
                      className='searchs'
                      onClick={(e)=>{setOpenDate(!openDate)}}
                      InputProps ={{
                          startAdornment: <EventIcon style={{display:'flex',justifyContent:'end'}}/>
                      }}
                      />
                      <div className='datepicker'>
                      {openDate && (
                        <DateRange 
                          className='data-range-wow'
                          onChange={(item)=>{
                            setDate([item.selection]);
                            let x = formatDate([item.selection][0].startDate);
                            let y = formatDate([item.selection][0].endDate);
                            setformated(getDate(x,y))
                            setCheckIn(x);
                            setCheckOut(y);
                            if(x===y ){
                              setOpenDate(true);
                            }
                            else{
                              setOpenDate(false);
                            } 
                          }}
                          minDate={new Date()}
                          moveRangeOnFirstSelection={false}
                          ranges={date}
                          rangeColors={['#d66']}
                        />
                      )}
                      </div>
                      {cities &&
                      <StyledAutocomplete
                        options={cities}
                        getOptionLabel={(option) => option.text}
                        value={selectedCity} // Use `value` instead of `defaultValue`
                        onChange={(event, newValue) => {
                          setSelectedCity(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t('SearchLocation')}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: <FmdGoodIcon />,
                              endAdornment: (
                                <IconButton
                                  onClick={() => {
                                    navigate(`?province=${params.inputProps.value}&checkin=${checkIn}&checkout=${checkOut}`);
                                    window.location.reload();
                                  }}
                                >
                                  <SearchIcon />
                                </IconButton>
                              ),
                              disableUnderline: true,
                            }}
                          />
                        )}
                        sx={{
                          minWidth: 50,
                          fontSize: '12px',
                        }}
                      />
                  }
                  </Search>
              </div>
            </div>        
            {show &&
            <div style={{boxShadow: '0 4px 2px -2px gray', marginBottom:10}}>
            <div className='container-fluid'>
              <div className='row mt-3 pt-4 ' style={{padding: '10px 10px', display: 'flex', width: '100%' }}>
                  <div className='col-lg-3 col-sm-6 col-12'> 
                    <h5 style={{fontWeight:"600"}} className='mb-2 '> {t("Pricing")}</h5>
                    <div className='ms-3 me-4'>
                    <Slider
                      getAriaLabel={() => 'Price range'}
                      value={value}
                      onChange={handleChange}
                      color='warning'
                      valueLabelDisplay="auto"
                      valueLabelFormat={valueText}
                      getAriaValueText={valueText}
                      marks={marks}
                      max={4000}
                      min={50}
                    />
                    </div>
                    <div className='mb-3'>
                    <h5 style={{fontWeight:"600"}} className='mt-4 '> {t("Co-Working")} </h5>
                    {
                      publicArea.map((item)=>(
                        <HoverCheckbox
                        onChange={() => handleCategoryChanges(item)}
                        checked={selectedPublicArea.indexOf(item.code) > -1} 
                        text={item.name}/>
                      ))
                    }
                    </div>
                  </div>
                  <div className='col-lg-3 col-sm-6 col-12'>
                    <div className=' mb-3 '>

                    <h5 style={{fontWeight:"600"}} className='mb-4 '> {t("Amenities")} </h5>
                    {
                      standardAmenities.map((item)=>(
                        <HoverCheckbox
                        onChange={() => handleCategoryChanges(item)}
                        checked={selectedAmenities.indexOf(item.code) > -1} 
                        text={item.name}/>
                      ))
                    }
                    </div>
                  </div>
                  <div className='col-lg-2 col-sm-5 col-12'>
                    <div className='mb-3'>

                    <h5 style={{fontWeight:"600"}} className='mb-4 '> {t("Additional support")}</h5>
                    {
                      additionalSupport.map((item)=>(
                        <HoverCheckbox
                        onChange={() => handleCategoryChanges(item)}
                        checked={selectedAdditional.indexOf(item.code) > -1} 
                        text={item.name}/>
                      ))
                    }
                    </div>
                  </div>

                  <div className='col-lg-2 col-sm-4 col-12'>
                    <div className='mb-3'>

                    <h5 style={{fontWeight:"600"}} className='mb-4 '> {t("Activities")}</h5>
                    {
                      activities.map((item)=>(
                        <HoverCheckbox
                        onChange={() => handleCategoryChanges(item)}
                        checked={selectedActivies.indexOf(item.code) > -1} 
                        text={item.name}/>
                      ))
                    }
                    </div>
                  </div>
                  
                  <div className='col-lg-2 col-sm-3 col-12'>
                    <div className=''>

                    <h5 style={{fontWeight:"600"}} className='mb-4 '> {t("Terrain")}</h5>
                    {
                      terrain.map((item)=>(
                        <HoverCheckbox
                        onChange={() => handleCategoryChanges(item)}
                        checked={selectedTerrain.indexOf(item.code) > -1} 
                        text={item.name}/>
                      ))
                    }
                    </div>
                  </div>
              </div>
              <div className='row mt-3 pt-4 ' style={{padding: '10px 10px',alignContent:'flex-end',justifyContent:'flex-end', display: 'flex', width: '100%' }}>
              <button onClick={()=>{setShow(false)}} className='w-auto btn btn-outline-dark  mx-2 p-2'> {t("Cancel")}</button>
                <button onClick={()=>{
                  setShow(false);
                  localStorage.setItem('ActivitiesCamps',JSON.stringify(selectedActivies));
                  localStorage.setItem('AdditionalCamps',JSON.stringify(selectedAdditional));
                  localStorage.setItem('AmenitiesCamps',JSON.stringify(selectedAmenities));
                  localStorage.setItem('publicAreaCamps',JSON.stringify(selectedPublicArea));
                  localStorage.setItem('TerrainCamps',JSON.stringify(selectedTerrain));
                  window.location.reload();
                }} className='w-auto btn btn-dark p-2'> {t("Apply Filters")}</button>
              </div>
              </div>
            </div>
            }
            <div className='container-fluid'>
              <div onClick={()=>{setShow(false)}} className='row'>
                <div className='py-3 col-lg-7 col-sm-12'>
                <div className='row g-1'>
                  {loaded && camps.length > 0 && camps.map((item)=>(
                    <div className='col-lg-6 pb-2 col-md-6 col-sm-6 p-3'>
                      <LodgeCard handleNavigate={handleNavigationToCamp} props={item}/>
                    </div>
                  ))
                  }
                  {loaded && camps.length > 0 &&
                    <div className='d-flex align-items-center justify-content-center'>
                    <Pagination 
                    count={pages} 
                    page={parseInt(page)}  
                    onChange={(event,value)=>{ 
                      setPage(value);
                      localStorage.setItem('skip',value); 
                      window.location.reload();
                    }}
                    color="warning" />
                  </div>
                  }
                </div>
                </div>
                <div className='col-lg-5 col-sm-12'>
                  {
                    loaded &&  camps.length > 0 &&           
                    <MapContainer  center={[38.9637,35.2433]} zoom={5} style={{ minHeight:'400px',height: '100%', width: '100%' }}>
                      <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                      { loaded && camps.map((item)=>(
                         item.coordinate?.latitude !== undefined &&  item.coordinate?.longitude !== undefined &&
                          <Marker 
                          icon={customMarker}
                          position={[parseFloat(item.coordinate.latitude),parseFloat(item.coordinate.longitude)]}> 
                            <Popup>
                                <MapItem props={item} />
                            </Popup>
                          </Marker>
                        ))
                      }
                  </MapContainer>
                  }
                </div>
                {
                  loaded && camps.length == 0 && <NotFound/>
                }
              </div>
            </div>
          </div>
 
        </div>
        <Footer onClick={()=>{setShow(false)}} current="home"/>
        <LoadingModal visible={!loaded}/>
    </>
    )
}
