import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enJSON from './resources/locale/en.json'
import trJSON from './resources/locale/tr.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'tr',
    fallbackLng: 'tr',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      tr: {
        translation: 
         {...trJSON}
      },
      en: {
         translation: 
          {...enJSON}
      },

    }
});

export default i18n;