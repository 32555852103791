import React , { useEffect, useState }from 'react';
import { Footer,LoadingModal,Navbar} from '../../common';
import { Domain } from '../../Domain';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useNavigate } from 'react-router-dom';
import './allblogs.css'
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
export const AllBlogs = () => {
    const [blogs,setBlogs] = useState([])
    const navigate = useNavigate();
    const [loaded,setLoaded] = useState(false)

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(`${Domain}/api/blogs`, requestOptions)  
        .then(response => response.json())
        .then(result => {
            setBlogs(result);
            setLoaded(true)
        })
        .catch(error => {
            setLoaded(true);
            console.log('error', error);

        });
      }, [])

    const fortmattedTitle = (title) =>{
        const teezy = title.toLowerCase().replace(/\s+/g, '-')
        return teezy;
    }

      
    return (  
        loaded ?    
        <>
            <Helmet>
			  <title>Kamplasana - Blogs</title>
              <meta name="description" content={t('BlogsMetaDescription')} />
			</Helmet>
            <div className='bg-main pb-1'>
                <Navbar isSearchVisible={false} />
            </div>
            <div className='pt-2 pb-4 home-body overflow-hidden'>               
                  <div className='content-timeline'>
                    <div className="content-timeline__tab">
                      <h4 className="content-timeline__tab__title global-title">Son Bloglar</h4>
                      <div className="content-timeline__tab__rss hide-phone"><a href='/blogs' target="_blank">
                      <svg style={{width:24,height:24}} viewBox="0 0 24 24">
                      <path fill="currentColor" d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z"></path>
                      </svg>
                      </a></div>
                    </div>
                    { blogs.length > 0 ? blogs.map((blog,index) =>(

                          <div className='content-timeline__list'>
                            <div className="content-timeline__item">
                                <div className="content-timeline--left">
                                    <div className="content-timeline__time">
                                        <div>
                                            <CampaignIcon/>
                                        </div>
                                        <span className="content-timeline__time__timeago"><time datetime="2023-12-13T15:12:05+0200">{3 + index + index} saat önce</time></span>
                                    </div>
                                </div>
                                <div className="content-timeline--right">
                                    <div className="content-timeline__media">
                                        <a onClick={()=>{
                                            navigate(''+fortmattedTitle(blog.title));
                                     }} className="content-timeline__link clearfix" title={blog.title}>
                                            <div onClick={()=>{
                                            navigate(''+ fortmattedTitle(blog.title));
                                     }} className="content-timeline__media--inset">
                                                <img className="content-timeline__media__image lazyloaded" src={blog.image[0]} style={{display:'inline'}}/>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="content-timeline__detail">
                                        <div className="content-timeline__detail__container">
                                            <div className="content-timeline__detail--top">
                                                <span className="content-timeline__detail__time hide"><time >{3 + index + index} saat önce</time></span>
                                            </div>
                                            <a onClick={()=>{
                                            navigate(''+fortmattedTitle(blog.title));
                                        }}  className="content-timeline__link clearfix" >
                                                <h6  className="content-timeline__detail__title"><span className="content-timeline--underline"> {blog.title}
                                                </span></h6>
                                            </a>
                                            <div className="content-timeline__detail--bottom">
                                                <a className="content-timeline__detail__author hide-phone">Admin</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                      </div>
                    ))
                    :
                    <>
                    </>
                    }
                    </div>
                </div>
            <Footer current={''}/>
        </>  
        :
        <LoadingModal visible={!loaded}/>
    )
}
