import React from 'react';
import BTMLogo from './btmLogo.png';
import { useTranslation } from 'react-i18next';

export const Cooperates = () => {
    const { t, i18n } = useTranslation();

  return (
    <div className='container overflow-hidden mb-5'>
        <div className='row'>
            <div className='col-lg-3 col-md-5 col-sm-6 mt-2'>
                <h3 style={{color:"#939E9E",fontWeight:600}}>
                 {t('companies')}
                </h3>
            </div>
            <div className='col-lg-8 col-md-7 col-sm-6 mt-3'>
                <img style={{width:'auto',height:70}} src={BTMLogo}/>

            </div>

        </div>
        
    </div>
  )
}
