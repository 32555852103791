import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import {Avatar,IconButton, TextField} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import {convertToDetailedDate, formatDate,getDate,isValidDateFormat,stringAvatar} from '../../utils';
import { Search,StyledAutocomplete,StyledInputBase } from './comp/styledInputs';
import Logo from '../../resources/images/LogoName.png';
import SearchIcon from '@mui/icons-material/Search';
import EventIcon from '@mui/icons-material/Event';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { ResponsiveNavbar } from './ResponsiveNavbar';

export const LoggedNavbar = (props) => {
    const container_type = props.page==='main' ? 'container' : 'container-fluid';
    const [show,setShow] = useState(false);
    const userInfo = props.userInfo ? props.userInfo : "";
    const navigate = useNavigate();
    const location = useLocation(); 
    const searchParams = new URLSearchParams(location.search);
    const [checkIn,setCheckIn] = useState( isValidDateFormat(searchParams.get('checkin')) ? searchParams.get('checkin'):'');
    const [checkout,setCheckout] = useState( isValidDateFormat(searchParams.get('checkout')) ? searchParams.get('checkout'): '');

    const [openDate, setOpenDate] = useState(false);
    const { t } = useTranslation();
    const [date, setDate] = useState([{
        startDate: isValidDateFormat(checkIn) ? new Date(convertToDetailedDate(checkIn)) : new Date(),
        endDate: isValidDateFormat(checkout) ? new Date(convertToDetailedDate(checkout)) :  null,
        key:  'selection' 
    }]);
    const [formatted,setformated] = useState( isValidDateFormat(checkIn) && isValidDateFormat(checkout) ? getDate(checkIn,checkout) : t('AddDate'));
    const [value] = useState(t('SearchLocation'));
    const [search,setSearch] = useState('');
    const [selectedCity, setSelectedCity] = useState(null);
    const query2 = searchParams.get('province');
    const [cities,setCities] = useState([]);
    useEffect(() => {
        const requestOptions = {
            method: "GET",
            redirect: "follow"
          };
          
          fetch("https://www.kamplasana.com/api/cities", requestOptions)
            .then((response) => response.text())
            .then((result) => {
              const resultJson = JSON.parse(result);
              setCities(resultJson)
            })
            .catch((error) => console.error(error));
    }, [])
    useEffect(() => {
        const initialCity = cities.find(city => city.text === query2);
        setSelectedCity(initialCity || null);
      }, [query2, cities]);
    
    const renderLeftSide = () =>(
        <>
            <div className='col-2 col-md-1 col-sm-1 barscol'>
                <button onClick={()=>{setShow(true)}} className='btn btn-light'>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                </button>
            </div>
            <div className='logo-col col-1 col-md-2 col-sm-2'>
                <div style={{cursor:'pointer'}} onClick={()=>{navigate('../home')}}  className="logo">
                    <img style={{width:200,padding:20,borderBottomLeftRadius:20,borderBottomRightRadius:20}} src={Logo}/>
                </div>
            </div>
        </>
    )
    
    const renderMiddleSide = () =>(
        props.isSearchVisible && 
        <Search className='loggednav'>
            <StyledInputBase
                placeholder={formatted}
                className='searchs'
                onClick={(e)=>{setOpenDate(!openDate)}}
                InputProps ={{
                    startAdornment: <EventIcon className='me-2'/>
                }}
            />
            <div className='datepicker' style={{zIndex:90}}>
                {openDate && (
                    <DateRange 
                        onChange={(item) => {
                            setDate([item.selection]);
                            let x = formatDate([item.selection][0].startDate);
                            let y = formatDate([item.selection][0].endDate);
                            setformated(getDate(x,y))
                            setCheckIn(x);
                            setCheckout(y);
                            if(x===y ){
                                setOpenDate(true);
                            }
                            else{
                                setOpenDate(false);
                            }                                     
                        }}
                        minDate={new Date()}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        rangeColors={['#d66']}
                    />
                )}
            </div>
            <StyledAutocomplete
                options={cities}
                className='searchs-last'
                getOptionLabel={(option) => option.text}
                value={selectedCity} // Use `value` instead of `defaultValue`
                onChange={(event, newValue) => {
                  setSelectedCity(newValue);
                }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={t('SearchLocation')}
                    InputProps={{
                    ...params.InputProps,
                    startAdornment:    <FmdGoodIcon className='me-2' />,
                    endAdornment: (
                        <IconButton
                        onClick={() => {
                            let dateSplitted = formatted.split('--') || ['',''];
                            navigate(`?province=${search}&checkin=${dateSplitted[0].trim()}&checkout=${dateSplitted[1].trim()}`); 
                            window.location.reload();
                        }}
                        >
                        <SearchIcon />
                        </IconButton>
                    ),
                    disableUnderline: true,
                    }}
                />
                )}
            />
        </Search>
    )
    
    const renderRightSide = () =>(
        <div style={{flex:1}} className='navlinks-cont col-2 col-md-5 col-sm-5'>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/about')}} className="nav_link">{t('Aboutus')}</li>
            </div>
            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/camps')}} className="nav_link">{t('Camps')}</li>
            </div>

            <div className='nav-links-mobile'>
                <li onClick={()=>{navigate('/tours')}} className="nav_link">{t('Tours')}</li>
            </div>

            <div className='nav-links-mobile'>
                <li className="nav_link" onClick={()=>{navigate('../blogs')}}>{t('Blog')}</li>
            </div>
            {
                userInfo.role==='campingSeller' ? 
                <button onClick={()=>{navigate('../host/camps/new')}} className='btn btn-dark signup-fluid'>
                    {t('CreateCamp')}
                </button>
                : userInfo.role==='campingTour' &&  
                <button onClick={()=>{navigate('../tour/tours/new')}} className='btn btn-dark signup-fluid'> 
                    {t('CreateTour')}
                </button>
            }
            <div onClick={()=>{
                if(userInfo.role==='camper'){
                    navigate('../profile');
                }
                else if(userInfo.role==='admin'){
                    navigate('../admin/dashboard');
                }
                else if(userInfo.role==='campingSeller'){ 
                    navigate('../host/dashboard');
                }
                else if(userInfo.role==='campingTour'){ 
                    navigate('../tour/dashboard');
                }
            }} className='nav-links-mobile ms-2'>
                <Avatar style={{cursor:'pointer'}} {...stringAvatar(userInfo.firstName,userInfo.lastName)}  />
            </div>
        </div>
    )

    return (
        userInfo !== 'empty' &&
        <div className='bg-main pb-5'>
            <div className={` pb-5 pt-3 px-5 nav-cont container-fluid ${container_type}`}>
                {renderLeftSide()}
                {renderMiddleSide()}
                {renderRightSide()}
            </div>
            <ResponsiveNavbar 
                setShow={setShow}
                show={show}
                navigate={navigate}
                Logo={Logo}
                logged={false}
                t={t}
            />
        </div>
    )   
}
