import React from 'react';
import { Safety } from '../Home/Components/Safety';
import { Footer,Navbar } from '../../common';
import { Container, Typography, Box,Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export const TermsConditions = () => {
  return (
        <div className='bg-main'>
            <Helmet>
                <title>Kamplasana | Şartlar ve Koşullar</title>  
                <meta name="description" content={"Kamplasanannın Şartlar ve Koşullar "} />
            </Helmet>
            <div className='home-body overflow-hidden'>
            <Navbar isSearchVisible={false}/>       
            </div>
            <div className='px-3 py-2'>
            
        
            <Container maxWidth="xl" sx={{ marginTop: 4}}>

                <Grid container spacing={5} className='mb-4'>
                    <Grid item xs={12} >
                    <Box sx={{background: 'white',padding:5,borderRadius:10}} >
                    <Typography variant="h2" style={{fontWeight:500}} gutterBottom>
                    Şartlar ve Koşullar
                    </Typography>
            <div>
        <p><strong>Son Güncelleme: 12.11.2024</strong></p>
        
        <p>Kamplasana'ya hoş geldiniz! Bu Şartlar ve Koşullar, web sitemizin kullanımına ilişkin kuralları ve düzenlemeleri özetlemektedir. Kamplasana’ya erişerek ve kullanarak, bu Şartlar ve Koşulları kabul etmiş sayılırsınız. Bu şartların herhangi bir kısmını kabul etmiyorsanız, lütfen hizmetlerimizi kullanmayın.</p>

        <h2>1. Şartların Kabulü</h2>
        <p>Kamplasana'ya erişerek, bu Şartlara uymayı ve bunlarla bağlı olmayı kabul etmiş olursunuz. Bu şartları zaman zaman güncelleyebiliriz ve bunları düzenli olarak gözden geçirmek sizin sorumluluğunuzdadır.</p>

        <h2>2. Kullanıcı Hesapları</h2>
        <p>Kamplasana’nın bazı özelliklerini kullanmak için bir hesap oluşturmanız gerekebilir. Hesap bilgilerinizin gizliliğini korumaktan ve hesabınız altında gerçekleşen tüm etkinliklerden siz sorumlusunuz.</p>

        <h2>3. Kamplasana’nın Kullanımı</h2>
        <p>Kamplasana yalnızca kişisel kullanım için sunulmaktadır. Kamplasana’yı yasadışı veya yetkisiz bir amaçla kullanamazsınız. Kullanıcılar, herhangi bir yasa, kural veya düzenlemeyi ihlal etmekten kaçınmalıdır.</p>

        <h2>4. Rezervasyon ve İptaller</h2>
        <ul>
            <li><strong>Rezervasyon Politikası:</strong> Tüm rezervasyonlar, Kamplasana’nın rezervasyon prosedürlerine uygun olarak yapılmalıdır. Ödeme alındığında rezervasyonlar onaylanır.</li>
            <li><strong>İptal Politikası:</strong> İptal koşulları kamp alanına göre değişiklik gösterir. Rezervasyon sürecinde belirtilen iptal dönemine bağlı olarak geri ödeme yapılabilir.</li>
            <li><strong>Rezervasyonlarda Değişiklik:</strong> Kamplasana, hava durumu, acil durumlar veya diğer öngörülemeyen durumlar nedeniyle rezervasyonları değiştirme hakkını saklı tutar.</li>
        </ul>

        <h2>5. İş Ortağı Kamp Alanları</h2>
        <p>Kamplasana, güvenilir kamp alanı sağlayıcılarıyla iş ortaklığı yapmaktadır. Kaliteli bir deneyim sağlamaya çalışmamıza rağmen, üçüncü taraf kamp alanlarının eylemleri veya ihmalleri için sorumluluk kabul etmiyoruz.</p>

        <h2>6. Kullanıcı Davranışı</h2>
        <p>Aşağıdakileri yapmamayı kabul ediyorsunuz:</p>
        <ul>
            <li>Hakaret içeren, müstehcen veya yasadışı içerik yayınlamak veya paylaşmak.</li>
            <li>Kamplasana veya diğer kullanıcılara zarar verebilecek herhangi bir davranışta bulunmak.</li>
            <li>Botlar, tarayıcılar veya otomatik yöntemlerle siteye erişmek.</li>
        </ul>

        <h2>7. Fikri Mülkiyet</h2>
        <p>Kamplasana'daki tüm içerik, ticari markalar ve logolar, Kamplasana'nın mülkiyetindedir ve telif hakkı yasalarıyla korunmaktadır. İzinsiz kullanım yasaktır.</p>

        <h2>8. Sorumluluğun Sınırlandırılması</h2>
        <p>Kamplasana, "olduğu gibi" ve "mevcut olduğu şekliyle" sunulmaktadır. Web sitesinin güvenilirliği, doğruluğu veya kullanılabilirliği hakkında herhangi bir garanti vermiyoruz. Kamplasana ve bağlı kuruluşları, sitemizin kullanımından kaynaklanan doğrudan, dolaylı veya tesadüfi zararlardan sorumlu değildir.</p>

        <h2>9. Tazminat</h2>
        <p>Kamplasana’yı kullanımınızdan, bu şartları ihlal etmenizden veya üçüncü bir tarafın haklarını ihlal etmenizden kaynaklanan her türlü talep, zarar veya sorumluluktan Kamplasana’yı muaf tutmayı kabul ediyorsunuz.</p>

        <h2>10. Gizlilik Politikası</h2>
        <p>Gizliliğiniz bizim için önemlidir. Kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı ve koruduğumuzu anlamak için lütfen <a href="#">Gizlilik Politikamızı</a> inceleyin.</p>

        <h2>11. Şartlarda Değişiklikler</h2>
        <p>Kamplasana, bu Şartları dilediği zaman güncelleme hakkını saklı tutar. Kullanıcılar değişikliklerden haberdar edilecektir ve web sitesini kullanmaya devam etmeniz bu güncellenmiş şartları kabul ettiğiniz anlamına gelir.</p>

        <h2>12. İletişim</h2>
        <p>Bu Şartlarla ilgili sorularınız veya endişeleriniz için lütfen [iletişim bilgileri ekleyin] üzerinden bizimle iletişime geçin.</p>
    </div>
                    </Box>
                    </Grid>
                    
                </Grid>
            </Container>
            </div>
            <div className='bg-lgreen py-5'>
                <div className='home-body overflow-hidden'>
                    <Safety/>
                </div>
            </div>
            <div className='bg-light'>
                <div className='home-body overflow-hidden'>
                </div>    
            </div>
            <Footer current={''}/>

    </div>
  )
}
